/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React, { useRef, useEffect, useMemo } from 'react';
import {
    object, shape, string, array, func, bool, number,
} from 'prop-types';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { connect, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useMediaQuery } from '@material-ui/core';
import { trackEvent as track } from '../../../../../state/ducks/TagManager/ducks/TagManager/TagManager-Actions';
import Panel2 from '../../Panel';
import { ifExternalUrl } from '../../NUp/helper';
import { openPassportSideBar } from '../../../../../state/ducks/Passport/Passport-Action';
import { getFeatureFlags, getFeatureFlag, getPresentationFamily } from '../../../../../state/ducks/App/ducks/Config/Config-Selectors';
import convertAlphaDecToHex from '../../../../helpers/contentstack/convertAlphaDecToHex';
import positionVideoBox from '../../../../helpers/contentstack/copyPositionFlex';
import isVideoFormat from '../../../../helpers/isVideoFormat';

const useStyles = makeStyles((theme) => ({
    root: ({
        blockMargin, presentation_type, tabletbg, height, image, image_mobile, isTabletImage,
    }) => ({
        width: `${presentation_type?.indexOf('Fixed') !== -1 ? '1400px' : '100%'}`,
        maxWidth: `${presentation_type?.indexOf('Department') !== -1 ? '1920px' : '100%'}`,
        height: `${height}px` || '400px',
        backgroundPositionX: tabletbg || null,
        boxSizing: 'border-box',
        display: 'block',
        margin: blockMargin.top && blockMargin.bottom ? `${blockMargin.top}px auto ${blockMargin.bottom}px` : '0 auto',
        textDecoration: 'none',
        backgroundPosition: 'center center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        color: theme.palette.common?.black || '#000000', // TODO: Font color should come from CMS
        backgroundImage: `url(${image?.url}?auto=webp)`,
        [theme.breakpoints.down(1366)]: {
            backgroundImage: `url(${isTabletImage?.url || image?.url}?auto=webp)`,
        },
        [theme.breakpoints.down(768)]: {
            backgroundImage: `url(${image_mobile?.url}?auto=webp)`,
        },
        '@media screen and (max-width: 768px)': {
            width: '100% !important',
            backgroundPosition: 'center center',
        },
    }),
    rootWithGrid: ({
        blockMargin, presentation_type, tabletbg, height, image, image_mobile, isTabletImage,
    }) => ({
        width: `${presentation_type?.indexOf('Fixed') !== -1 ? '1400px' : '100%'}`,
        maxWidth: `${presentation_type?.indexOf('Department') !== -1 ? '1920px' : '100%'}`,
        height: `${height}px` || '400px',
        backgroundPositionX: tabletbg || null,
        boxSizing: 'border-box',
        display: 'block',
        margin: blockMargin.top && blockMargin.bottom ? `${blockMargin.top}px auto ${blockMargin.bottom}px` : '0 auto',
        textDecoration: 'none',
        backgroundPosition: 'center center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        color: theme.palette.common?.black || '#000000', // TODO: Font color should come from CMS
        backgroundImage: `url(${image?.url}?auto=webp)`,
        [theme.breakpoints.down(1366)]: {
            backgroundImage: `url(${isTabletImage?.url || image?.url}?auto=webp)`,
        },
        [theme.breakpoints.down(768)]: {
            backgroundImage: `url(${image_mobile?.url}?auto=webp)`,
        },
        '@media screen and (max-width: 1024px)': {
            width: '100% !important',
            backgroundPosition: 'center center',
        },
        '@media screen and (max-width: 768px)': {
            width: '100% !important',
            backgroundPosition: 'center center',
        },
    }),
    rootVideo: ({ blockMargin }) => ({
        boxSizing: 'border-box',
        padding: theme.spacing(3, 0, 0, 0),
        display: 'block',
        maxWidth: '1400px',
        width: '100%',
        height: '100%',
        margin: blockMargin.top && blockMargin.bottom ? `${blockMargin.top}px auto ${blockMargin.bottom}px` : theme.palette.heroSingle?.margin,
        textDecoration: 'none',
        color: theme.palette.common?.black || '#000000',
        [theme.breakpoints.down(1024)]: {
            width: '100%',
        },
        [theme.breakpoints.down(760)]: {
            '& span > div': {
                fontSize: '1em !important',
            },
            '& img': {
                width: '100%',
            },
        },
    }),
    overflowHidden: {
        overflow: 'hidden',
    },
    scrap: {
        // margin: `0 auto ${theme.spacing(3)}px auto`,
        padding: '10x 20px',
        textAlign: 'center',
        fontSize: '34px',
        color: theme.palette.common?.black || '#000000', // TODO: Font color should come from CMS
    },
    headline: {
    },
    headlineVideo: {
        textAlign: 'center',
        position: 'absolute',
        margin: '0 auto',
        top: '50%',
        right: '50%',
        transform: 'translate(50%, -50%)',
    },
    ctaLink: {
        textDecoration: 'none',
    },
    cta: {
        boxSizing: 'border-box',
        display: 'block',
        margin: '0 auto',
        fontSize: '18px',
        fontWeight: '800',
        borderRadius: '2px',
    },
    contentSectionWrapper: {
        margin: '0 auto',
    },
    contentSectionWrapperCopyPosition: {
        overflow: 'hidden',
        margin: '0 auto',
        height: '100%',
        width: '100%',
        textAlign: 'center',
        position: 'relative',
    },
    contentSection: {
        margin: '0px auto',
        padding: '30px 0',
        textAlign: 'center',
        display: 'table',
    },
    contentSectionVideo: {
        display: 'table',
    },
    contentSectionCopyPosition: ({ copyPosition }) => ({
        width: '40%',
        position: 'absolute',
        top: copyPosition?.top || '50%',
        right: copyPosition?.right || '50%',
        bottom: copyPosition?.bottom || '',
        left: copyPosition?.left || '',
        transform: 'translate(50%, -50%)',
        [theme.breakpoints.down(1024)]: {
            '& > div > div': {
                margin: '0 auto',
                fontSize: '0.8em !important',
            },
        },
        [theme.breakpoints.down(760)]: {
            '& span:first-of-type > div:first-of-type': {
                fontSize: '1.7em !important',
            },
            width: '25%',
        },
    }),
    tabletBanner: {
        '@media screen and (max-width: 960px)': {
            minHeight: '240px',
            height: '100% !important',
        },
    },
    absoluteContainer: {
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        display: 'flex',
    },
    relative: {
        position: 'relative',
    },
    objectFit: ({ height }) => ({
        objectFit: 'cover',
        height: height || 'auto',
        aspectRatio: '1.89/1',
    }),
    textShadow: {
        textShadow: '1px 1px 2px #00000030',
        '& svg': {
            filter: 'drop-shadow(1px 1px 1px #00000030)',
        },
    },
    videoBoxFood: {
        margin: '0 auto',
        width: '100%',
        maxWidth: '1400px',
        position: 'relative',
        '&:hover div': {
            transition: 'opacity .3s',
            opacity: 1,
        },
        '@media screen and (max-width: 1280.5)': {
            opacity: 1,
        },
    },
    videoControls: {
        position: 'absolute',
        left: '10px',
        bottom: '10px',
        zIndex: '1',
        display: 'flex',
        '& div': {
            cursor: 'pointer',
        },
    },
    videoControlPlay: {
        opacity: 0,
        marginRight: '.5em',
        width: 0,
        height: 0,
        borderWidth: '10px 0 10px 12px',
        borderColor: 'transparent transparent transparent #ffffff55',
        borderStyle: 'solid',
        '&:hover': {
            borderColor: 'transparent transparent transparent #ffffff',
        },
    },
    videoControlPause: {
        opacity: 0,
        border: 'solid 5px #ffffff55',
        borderWidth: '0 5px',
        height: '20px',
        width: '15px',
        '&:hover': {
            borderColor: '#ffffff',
        },
    },

}));

const position = (message_location) => {
    if (message_location !== undefined && message_location !== null) {
        let margin = 'center';
        switch (message_location.toLowerCase()) {
            case 'left':
                margin = { margin: '0 auto 0 0' };
                break;
            case 'center':
                margin = { margin: '0 auto' };
                break;
            case 'right':
                margin = { margin: '0 0 0 auto' };
                break;
            case 'no margin':
                margin = { margin: '0' };
                break;
            default:
                margin = { margin: '0 auto' };
                break;
        }
        return margin;
    }
    return {};
};

const HeroSingleDesktop = ({
    linking,
    message_collection,
    message_styling,
    message_location,
    image,
    image_mobile,
    tabletMobImage,
    presentation_type,
    height,
    overlays,
    trackEvent,
    isBot,
    tabletbg,
    featureFlags,
    passportOpenSideBar,
    container_attributes,
    blockMargin,
    isTabletImage,
    imageOpacity,
    messagePositioning,
    videoPoster,
    video,
    trackCustomClickstreamEventByType,
}) => {
    const presentationFamily = useSelector(getPresentationFamily);
    const isTabWidth = useMediaQuery('(max-width: 1025px)');
    const containerAttributePosition = container_attributes.find((attr) => {
        const attrKeys = Object.keys(attr);
        return attrKeys[0] === 'copy_position';
    });

    const backgroundColor = (container_attributes || []).find((attr) => {
        const attrKeys = Object.keys(attr);
        return attrKeys[0] === 'background_color';
    });

    const copyPosition = containerAttributePosition?.copy_position || {};
    const hasCopyPositionValues = ((Object.values(copyPosition)).filter((elem) => {
        if (typeof elem === 'string') {
            return elem.length > 0;
        }
        return false;
    })).length > 0;

    const svgPositionStyles = container_attributes.find((attr) => {
        const attrKeys = Object.keys(attr);
        return attrKeys[0] === 'svg_position';
    });
    const createVideoID = () => {
        const src = video?.desktop?.mp4?.url || image?.url || 'Video';
        /**
         * turn https://assets.contentstack.io/v3/assets/blt89dbf1c763ec00a6/blt39521815bb9e56cb/6511b146474d4d76f24f2c43/23_025_001_HD_PearTabletop-A15_1400x584_G0_M0_A1.mp4
         * into 23_025_001_HD_PearTabletop-A15_1400x584_G0_M0_A1
         * */
        const id = src.split('/')?.pop()?.split('.')[0] || 'ComponentVideo';
        return id;
    };
    const classes = useStyles({
        message_location, blockMargin, copyPosition, height, tabletbg, presentation_type, image, image_mobile, isTabletImage, tabletMobImage,
    });
    const isFlowerBrand = presentationFamily === 'flower';
    const ffHasHomepageGridStructure = useSelector(getFeatureFlag('has-homepage-grid-structure'));
    const rootCssStyle = ffHasHomepageGridStructure ? classes.rootWithGrid : classes.root;

    if (!linking && !message_styling && !message_collection) {
        return null;
    }
    if (image && image.url && linking.link) {
        const {
            link: { href = '', title = '' }, tracking_event_action = '', tracking_event_label = '', tracking_event_category = '',
        } = linking;
        const { url = '' } = image;
        const panelData = {
            message_collection,
            overlays,
            svgPositionStyles: svgPositionStyles?.svg_position,
        };

        useEffect(() => {
            trackCustomClickstreamEventByType('Impression');
        }, []);

        const handleClick = (e) => {
            if (title === 'Celebrations Passport'
                // VARIANT A is "Passport Enrolment Page"
                // CONTROL is "Passport Side Bar"
                && featureFlags['which-passport-sidebar-enabled-variant']?.variant === 'CONTROL') {
                e.preventDefault();
                passportOpenSideBar();
            }
            trackCustomClickstreamEventByType('Click');
        };
        const videoRef = useRef(null);
        const poster = () => {
            if (isTabWidth && videoPoster?.tablet?.url) {
                return `${videoPoster?.tablet?.url}?auto=webp`;
            }
            if (videoPoster?.desktop?.url) {
                return `${videoPoster?.desktop?.url}?auto=webp`;
            }
            return '';
        };

        const videoBox = useMemo(() => (
            <video ref={videoRef} id={`video_${createVideoID()}`} data-testid="video-box" poster={poster()} autoPlay loop muted playsInline width="100%" height="100%" className={classes.objectFit}>
                <source src={video?.desktop?.webm?.url || image?.url} type={video?.desktop?.webm?.content_type || image?.content_type} preload="metadata" />
                <source src={video?.desktop?.mp4?.url || image?.url} type={video?.desktop?.mp4?.content_type || image?.content_type} preload="metadata" />
                <track kind="captions" />
            </video>
        ), [video?.desktop?.webm?.url, video?.desktop?.mp4?.url, image?.url]);
        const videoBoxFood = () => {
            const playVideo = () => videoRef.current.play();
            const pauseVideo = () => videoRef.current.pause();

            const bgColor = backgroundColor?.background_color?.color?.color || null;
            const positionStyling = positionVideoBox(messagePositioning);

            return (
                <div className={classes.videoBoxFood}>
                    <div className={classes.videoControls}>
                        <div className={classes.videoControlPlay} onClick={playVideo} onKeyDown={playVideo} role="button" tabIndex="0" aria-controls={`video_${createVideoID()}`} aria-pressed="false" aria-label="Play Video" />
                        <div className={classes.videoControlPause} onClick={pauseVideo} onKeyDown={pauseVideo} role="button" tabIndex="0" aria-controls={`video_${createVideoID()}`} aria-pressed="false" aria-label="Pause Video" />
                    </div>
                    <Link
                        data-ga-category={tracking_event_category || ''}
                        data-ga-action={tracking_event_action || ''}
                        data-ga-label={tracking_event_label || ''}
                        onClick={(e) => {
                            handleClick(e);
                            trackEvent({
                                eventCategory: tracking_event_category || '',
                                eventAction: tracking_event_action || '',
                                eventLabel: tracking_event_label || '',
                            });
                            ifExternalUrl(e, href, true);
                        }}
                        tabIndex="-1"
                        to={href}
                        title={title}
                        style={{
                            width: `${presentation_type?.indexOf('Fixed') !== -1 || presentation_type?.indexOf('Full Responsive') !== -1 ? '1400px' : '100%'}`,
                            maxWidth: `${presentation_type?.indexOf('Department') !== -1 ? '1920px' : '100%'}`,
                            height: height ? `${height}px` : 'auto',
                            backgroundPositionX: tabletbg || null,
                        }}
                        className={`${rootCssStyle} ${classes.overflowHidden} ${video.apply_text_shadow ? classes.textShadow : ''}`}
                    >
                        <div className={`${classes.relative} ${hasCopyPositionValues ? classes.contentSectionWrapperCopyPosition : classes.contentSectionWrapper}`}>
                            {videoBox}

                            <div
                                style={{
                                    ...positionStyling,
                                }}
                                className={classes.absoluteContainer}

                            >
                                <div
                                    style={{ background: bgColor ? convertAlphaDecToHex(bgColor, imageOpacity) : 'transparent' }}
                                    className={`${hasCopyPositionValues ? classes.contentSectionCopyPosition : classes.contentSectionVideo}`}
                                    data-testid="hero-single-desktop-video"
                                >
                                    <Panel2
                                        data={panelData}
                                        styleData={message_styling}
                                        inVideoBox
                                    />
                                </div>
                            </div>
                        </div>
                    </Link>
                </div>
            );
        };

        if (isVideoFormat(image?.content_type || video?.desktop?.mp4?.content_type || video?.desktop?.webm?.content_type) && presentationFamily === 'food') {
            return <>{videoBoxFood()}</>;
        }

        if (isVideoFormat(image?.content_type || video?.desktop?.mp4?.content_type || video?.desktop?.webm?.content_type)) {
            return (
                <Link
                    data-ga-category={tracking_event_category || ''}
                    data-ga-action={tracking_event_action || ''}
                    data-ga-label={tracking_event_label || ''}
                    onClick={(e) => {
                        handleClick(e);
                        trackEvent({
                            eventCategory: tracking_event_category || '',
                            eventAction: tracking_event_action || '',
                            eventLabel: tracking_event_label || '',
                        });
                        ifExternalUrl(e, href, true);
                    }}
                    to={href}
                    tabIndex="-1"
                    title={title}
                    className={classes.rootVideo}
                >
                    <div className={classes.contentSectionWrapperCopyPosition}>
                        <video autoPlay loop muted width="100%" height="100%">
                            <source src={video?.desktop?.webm?.url || image?.url} type={video?.desktop?.webm?.content_type || image?.content_type} preload="metadata" />
                            <source src={video?.desktop?.mp4?.url || image?.url} type={video?.desktop?.mp4?.content_type || image?.content_type} preload="metadata" />
                            <track kind="captions" />
                        </video>

                        <div className={`${hasCopyPositionValues ? classes.contentSectionCopyPosition : classes.headlineVideo}`} data-testid="hero-single-desktop-video">
                            <Panel2
                                data={panelData}
                                styleData={message_styling}
                            />
                        </div>
                    </div>
                </Link>
            );
        }

        return (
            <Link
                data-ga-category={tracking_event_category || ''}
                data-ga-action={tracking_event_action || ''}
                data-ga-label={tracking_event_label || ''}
                onClick={(e) => {
                    handleClick(e);
                    trackEvent({
                        eventCategory: tracking_event_category || '',
                        eventAction: tracking_event_action || '',
                        eventLabel: tracking_event_label || '',
                    });
                    ifExternalUrl(e, href, true);
                }}
                to={href}
                tabIndex="-1"
                title={title}
                className={isFlowerBrand ? `${rootCssStyle} ${classes.tabletBanner}` : rootCssStyle}
            >
                {isBot && <img src={url} alt={image.title} />}
                <div className={`${hasCopyPositionValues ? classes.contentSectionWrapperCopyPosition : classes.contentSectionWrapper}`}>
                    <div className={`${hasCopyPositionValues ? classes.contentSectionCopyPosition : classes.contentSection}`} style={position(message_location)} data-testid="hero-single-desktop">
                        <Panel2
                            data={panelData}
                            styleData={message_styling}
                        />
                    </div>
                </div>
            </Link>
        );
    }
    return <></>;
};

HeroSingleDesktop.propTypes = {
    linking: shape({
        link: shape({
            href: string.isRequired,
            title: string.isRequired,
        }).isRequired,
    }).isRequired,
    message_collection: array,
    overlays: object.isRequired,
    message_styling: array,
    message_location: string.isRequired,
    image: shape({
        url: string,
        content_type: string,
    }).isRequired,
    image_mobile: shape({
        url: string,
        content_type: string,
    }),
    isTabletImage: shape({
        url: string,
    }),
    presentation_type: string,
    height: string,
    trackEvent: func.isRequired,
    isBot: bool,
    tabletbg: string,
    tabletMobImage: bool,
    featureFlags: shape({
        'which-passport-sidebar-enabled-variant': shape({
            variant: string,
        }),
    }),
    passportOpenSideBar: func,
    container_attributes: array,
    blockMargin: object,
    imageOpacity: number,
    messagePositioning: string,
    videoPoster: shape({
        desktop: shape({
            url: string,
        }),
        tablet: shape({
            url: string,
        }),
        mobile: shape({
            url: string,
        }),
    }),
    video: object,
    trackCustomClickstreamEventByType: func,
};
HeroSingleDesktop.defaultProps = {
    height: '',
    isBot: false,
    tabletbg: '',
    message_collection: [],
    message_styling: [],
    image_mobile: {},
    isTabletImage: {},
    presentation_type: 'both',
    tabletMobImage: false,
    featureFlags: {},
    passportOpenSideBar: () => {},
    container_attributes: [],
    blockMargin: {},
    imageOpacity: null,
    messagePositioning: null,
    videoPoster: {},
    video: {},
    trackCustomClickstreamEventByType: () => { },
};

const mapStateToProps = (state) => ({
    featureFlags: getFeatureFlags(state),
});

const mapDispatchToProps = (dispatch) => ({
    trackEvent: bindActionCreators(track, dispatch),
    passportOpenSideBar: bindActionCreators(openPassportSideBar, dispatch),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(HeroSingleDesktop);
